import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect, useContext, Suspense, useMemo, useCallback } from 'react'
import { FormattedMessage, useIntl } from "react-intl";
import { BrowserRouter, Routes, Route, NavLink, Navigate } from 'react-router-dom';
import moment from 'moment';
import CryptoJS from 'crypto-js';

import packageJson from '../package.json';
import preval from 'preval.macro';

import 'antd/dist/antd.min.css';
import { Tooltip } from 'antd';

import logo from './images/sidebar/gv_cloud_logo.svg';
import logoShort from './images/sidebar/gv_cloud_logo_short.svg';
import './App.css';

import { EnumAccountType, EnumWebSocketCmd, EnumActionType, EnumThemeType, EnumErrorMessage, EnumMessageType, MessageTypeString } from './modules/ASUtils/ASConfig';
import { Constants, AppContext, AppContextProvider } from './Utils';
import { LoadingMask, ShowAlertMessage } from './modules/Common/Common';
import { useVMSGraphql } from './modules/VMS/VMSGraphql';
import SVGIcon from './icons.js';

const Monitor = React.lazy(() => import('./modules/Monitor/Monitor'));
const UserList = React.lazy(() => import('./modules/UserList/UserList'));
const UserSetting = React.lazy(() => import('./modules/UserSetting/UserSetting'));
const Region = React.lazy(() => import('./modules/Region/Region'));
const AccessRule = React.lazy(() => import('./modules/AccessRule/AccessRule'));
const SpecialDay = React.lazy(() => import('./modules/SpecialDay/SpecialDay'));
const Alert = React.lazy(() => import('./modules/Alert/Alert'));
const AccessLog = React.lazy(() => import('./modules/AccessLog/AccessLog'));
// const LPRLog = React.lazy(() => import('./modules/LPRLog/LPRLog'));
const SystemLog = React.lazy(() => import('./modules/SystemLog/SystemLog'));
const Operators = React.lazy(() => import('./modules/Operators/Operators'));
const ShareLink = React.lazy(() => import('./modules/ShareLink/ShareLink'));
const Scenario = React.lazy(() => import('./modules/Monitor/Scenario'));
const Notification = React.lazy(() => import('./modules/Notification/Notification'));
const DoorBellSummary = React.lazy(() => import('./modules/Common/DoorBellSummary'));
const AccountStatistic = React.lazy(() => import('./modules/AccountStatistic/AccountStatistic'));
const ImportUserState = React.lazy(
    () => import("./modules/UserList/ImportUser").then(module => ({default: module.ImportUserState}))
);
const ClientShareLink = React.lazy(() => import('./modules/ShareLink/ClientShareLink'));

const dateTimeStamp = preval`module.exports = new Date().toLocaleDateString() + ' ' + new Date().getHours().toString().padStart(2, '0') + ':' + new Date().getMinutes().toString().padStart(2, '0') + ':' + new Date().getSeconds().toString().padStart(2, '0');`

function Application() {
	const intl = useIntl();
	const vmsGraphql = useVMSGraphql();
	const [isLogin, setIsLogin] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const [showSites, setShowSites] = useState(false);
	const [showAccount, setShowAccount] = useState(false);
	const [showLockdown, setShowLockdown] = useState(false);
	const [alertMsg, setAlertMsg] = useState({type: 'error', msg: '', onClose: null});

	const {accountInfo, verifyUser, ajaxLogin, addWSNotification, removeWSNotification, sendWS, debugMsg, getDeviceList, lockdownRegions} = useContext(AppContext);

	const profileAvatar = useMemo(() => {
		if (accountInfo.profile_picture) {
			return <img alt='' src={accountInfo.profile_picture} onLoad={e => e.target.classList.add('loaded') } />
		}
		return null;
	}, [accountInfo.profile_picture]);

	useEffect(() => {
		// create meta tags
		createMeta('version', packageJson.version);
		createMeta('build-time', dateTimeStamp);

		verifyUser(() => {
			ajaxLogin((success, accountInfo) => {
				setIsLogin(success);
				if (success) {
					setTimeout(() => {	// must get device list for lockdown status
						getDeviceList();
					}, 1000);
				} else {
					setAlertMsg({...alertMsg, msg: EnumErrorMessage[accountInfo] ? <FormattedMessage id={EnumErrorMessage[accountInfo]} /> : accountInfo });
				}
			});
		});
		
				
		setIsOpen(window.localStorage.getItem(Constants.storageNames.openSiderbar) === '1');

		const handleClickOutside = e => {
			if (!e.target.closest('.gv-service-panel') && !e.target.closest('.app-sidebar-brand')) {
				setShowSites(false);
			}
			if (!e.target.closest('.gv-account-panel') && !e.target.closest('.app-sidebar-photo')) {
				setShowAccount(false);
			}
		};
		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		document.body.classList.remove('dark');
		if (accountInfo.theme === EnumThemeType.Dark ||
			(accountInfo.theme === EnumThemeType.OSDefault && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
				
			document.body.classList.add('dark');
		}
	}, [accountInfo.theme]);

	const createMeta = (name, content) => {
		var meta = document.createElement('meta');
		meta.name = name;
		meta.content = content;
		document.head.append(meta);
	};

	const handleLogoutClick = useCallback((e) => {
		sendWS({cmd_id: EnumWebSocketCmd.ACCOUNT_LOGOUT});
		window.localStorage.removeItem(Constants.storageNames.accountInfo);
	}, [sendWS]);

	const receiveNotification = useCallback((data) => {
		switch (data.cmd_id) {
			case EnumWebSocketCmd.ACCOUNT_DATA_CHANGED:
				var action_type = parseInt(data.data1);
				if (action_type === EnumActionType.Delete) {	// Starting from v1.1.0, Cloud-Access no longer sends this command. Sent by Cloud-VMS instead.
					setAlertMsg({...alertMsg, msg: <FormattedMessage id="account_been_deleted" defaultMessage="Your login account has been deleted." />});
				} else if (action_type === EnumActionType.Edit) {
					if (process.env.REACT_APP_TYPE === 'dev') {
						debugMsg('Reload', 'privilege changed reload page!');
						alert('privilege changed reload page!');
					}
					setAlertMsg({
						...alertMsg,
						type: 'warning',
						msg: <FormattedMessage id='privilege_changed_reload_page' />,
						onClose: () => {
							setAlertMsg({...alertMsg, msg: ''});
							window.location.reload();
						}
					});
					setTimeout(() => {
						window.location.reload();
					}, 10 * 1000);
				}
				break;

			case EnumWebSocketCmd.ACCOUNT_SUSPEND:
				setAlertMsg({...alertMsg, msg: <FormattedMessage id="account_been_suspended" defaultMessage="Your account has been suspended." />});
				break;

			case EnumWebSocketCmd.INVALID_TOKEN:
				setAlertMsg({
					...alertMsg,
					type: 'warning',
					msg: <FormattedMessage id="err_msg_token_invalid" defaultMessage="Your session has timed out. Please log in again." />,
					onClose: () => {
						handleLogoutClick();
						window.location = `${Constants.gvCloudUrl}logout?redirect=/access&pathname=${window.location.pathname}`;
					}
				});
				break;

			case EnumWebSocketCmd.VMS_WEBSOCKET:
				if (data.action === 'LOGOUT') {
					if (data.type === 'SERVER_UPDATE' && process.env.REACT_APP_TYPE === 'dev') return;	// vms server maintain not logout in dev environment
					const doLogout = () => {
						handleLogoutClick();
						window.location = `${Constants.gvCloudUrl}logout?redirect=/access&pathname=${window.location.pathname}`;
					};

					setAlertMsg({
						...alertMsg,
						type: 'warning',
						msg: <FormattedMessage id={`VMS_${data.type}`} />,
						onClose: () => {
							setAlertMsg({...alertMsg, msg: ''});
							doLogout();
						}
					});
					setTimeout(doLogout, 10 * 1000);
				} else if (data.type === 'ACCOUNT_USER_CHANGE' && data.id === accountInfo.id) {
					if (data.action === 'DELETE') {
						setAlertMsg({...alertMsg, msg: <FormattedMessage id="account_been_deleted" defaultMessage="Your login account has been deleted." />});

					} else if (data.action === 'UPDATE' && data.master !== undefined && data.master !== accountInfo.master) {
						setAlertMsg({
							...alertMsg,
							type: 'warning',
							msg: <FormattedMessage id='privilege_changed_reload_page' />,
							onClose: () => {
								setAlertMsg({...alertMsg, msg: ''});
								window.location.reload();
							}
						});
						setTimeout(() => {
							window.location.reload();
						}, 10 * 1000);
					}
				}
				break;

			case EnumWebSocketCmd.ABNORMAL_CLOSE_WEBSOCKET:
				const storageName = 'lastAbnormalClosed';
				const lastAbnormalClosed = parseInt(window.localStorage.getItem(storageName)) || 0;
				window.localStorage.setItem(storageName, new Date().getTime());
				setAlertMsg({
					...alertMsg,
					type: 'warning',
					msg: <FormattedMessage id='connection_closed_abnormally' />,
					onClose: () => {
						window.localStorage.removeItem(storageName);
						setAlertMsg({...alertMsg, msg: '', onClose: null});
						window.location.reload();
					}
				});
				if (!lastAbnormalClosed || (new Date().getTime()) - lastAbnormalClosed > 10 * 60 * 1000) {
					setTimeout(() => {
						window.location.reload();
					}, 10 * 1000);
				}

				break;
			default:
				break;
		}
	}, [accountInfo, debugMsg, handleLogoutClick, alertMsg]);

	useEffect(() => {
		if (isLogin) {
			addWSNotification(receiveNotification);
		}
		return () => {
			if (isLogin) {
				removeWSNotification(receiveNotification);
			}
		};
	}, [isLogin, addWSNotification, removeWSNotification, receiveNotification]);

	const handleSidebarDock = () => {
		if (isOpen) {
			window.localStorage.removeItem(Constants.storageNames.openSiderbar);
		} else {
			window.localStorage.setItem(Constants.storageNames.openSiderbar, '1');
		}
		setIsOpen(!isOpen);
	};

	const handelCloseAlertMsg = () => {
		setAlertMsg({...alertMsg, msg: ''});
		window.location = `${Constants.gvCloudUrl}?redirect=/access&pathname=${window.location.pathname}`;
	};

	const handleMapClick = (e) => {
		e.preventDefault();
		vmsGraphql.mutationTempToken((success, data) => {
			if (success) {
				window.open(`${Constants.gvMapUrl}?token=${data}`);
			} else {
				setAlertMsg({
					...alertMsg,
					type: 'warning',
					msg: data || <FormattedMessage id='err_msg_internal_error' />,
					onClose: () => {
						setAlertMsg({...alertMsg, msg: '', onClose: null});
					}
				});
			}
		});
	};

	const renderSideMenuItem = (linkTo, text, icon) => {
		return (
			<Tooltip placement='right' title={text} trigger={isOpen ? [] : ['hover']}>
				<li>
					<NavLink to={linkTo}>
						{icon}
						<span title={text}>{text}</span>
					</NavLink>
				</li>
			</Tooltip>
		);
	};

	return (
		<BrowserRouter>
		{
			process.env.REACT_APP_TYPE !== 'release' &&
			<div className='app-debug'>{`${process.env.REACT_APP_TYPE}-${packageJson.version}-${dateTimeStamp}`}</div>
		}

		{
			isLogin ?
			<div className='App'>
				<div className={'app-main' + (isOpen? ' open' : '')}>
					<aside className={`app-sidebar noselect ${lockdownRegions.length > 0 ? 'lockdown' : ''}`}>
													
						<div className='app-sidebar-brand' onClick={() => setShowSites(!showSites)}>
							<Tooltip placement={isOpen ? 'bottom' : 'bottomLeft'} title='GV-Cloud Apps' arrowPointAtCenter={true} trigger={['hover']}>
								<div>
									<img src={isOpen ? logo : logoShort} className="d-inline-block align-top" alt={process.env.REACT_APP_SITE_TITLE} />
								</div>
							</Tooltip>
						</div>

						<div className={'gv-service-panel' + (showSites ? ' show' : '')} onClick={(e) => setShowSites(false)}>
							<a href={`${Constants.gvCloudUrl}cloud_vms`} target="_blank" rel="noopener noreferrer">
								<SVGIcon.LogoVideoMonitor />
								<span><FormattedMessage id="cloud_vms" defaultMessage="Cloud VMS" /></span>
							</a>
							<NavLink to='/Monitor' className='active'>
								<SVGIcon.LogoAccessControl />
								<span><FormattedMessage id="access_control" defaultMessage="Access Control" /></span>
							</NavLink>
							{/* <a href={`/`} target="_blank" rel="noopener noreferrer" disabled={true} onClick={e => e.preventDefault()}>
								<SVGIcon.LogoIOT />
								<span><FormattedMessage id="iot" defaultMessage="IOT" /></span>
							</a> */}
							<Tooltip placement='bottom' title={<FormattedMessage id='no_permission' />} arrowPointAtCenter={true} trigger={accountInfo.master ? [] :['hover']}>
								<a href={accountInfo.master ? `${Constants.gvCloudUrl}vpn` : undefined} target="_blank" rel="noopener noreferrer" disabled={!accountInfo.master} className={accountInfo.master ? '' : 'disabled'}>
									<SVGIcon.LogoVPN />
									<span><FormattedMessage id="vpn" defaultMessage="VPN" /></span>
								</a>
							</Tooltip>
							<a href={Constants.gvMapUrl} target="_blank" rel="noopener noreferrer" onClick={handleMapClick}>
								<SVGIcon.LogoMap />
								<span><FormattedMessage id="map" defaultMessage="Map" /></span>
							</a>
							<Tooltip placement='bottom' title={<FormattedMessage id='no_permission' />} arrowPointAtCenter={true} trigger={accountInfo.master ? [] :['hover']}>
								<a href={accountInfo.master ? `${Constants.gvCloudUrl}audit_log` : undefined} target="_blank" rel="noopener noreferrer" disabled={!accountInfo.master} className={accountInfo.master ? '' : 'disabled'}>
									<SVGIcon.LogoAuditLog />
									<span><FormattedMessage id="audit_log" defaultMessage="Audit Log" /></span>
								</a>
							</Tooltip>
							<Tooltip placement='bottom' title={<FormattedMessage id='no_permission' />} arrowPointAtCenter={true} trigger={accountInfo.master ? [] :['hover']}>
								<a href={accountInfo.master ? `${Constants.gvCloudUrl}account_management` : undefined} target="_blank" rel="noopener noreferrer" disabled={!accountInfo.master} className={accountInfo.master ? '' : 'disabled'}>
									<SVGIcon.LogoAccountSetting />
									<span><FormattedMessage id="account_management" defaultMessage="Account Management" /></span>
								</a>
							</Tooltip>
						</div>

						<ul>
							{renderSideMenuItem('/Monitor', intl.formatMessage({id: "monitoring", defaultMessage: "Monitoring"}), <SVGIcon.Monitoring />)}
							{renderSideMenuItem('/UserList', intl.formatMessage({id: "user_list", defaultMessage: "User List"}), <SVGIcon.UserList />)}
							{renderSideMenuItem('/AccessLog', intl.formatMessage({id: "access_event_log", defaultMessage: "Access / Event Log"}), <SVGIcon.AccessLog />)}
							{/* {
								process.env.REACT_APP_ENABLE_VEHICLE &&
								renderSideMenuItem('/LPRLog', <FormattedMessage id={MessageTypeString(EnumMessageType.LPR)} />, <SVGIcon.LPRLog />)
							} */}
							{renderSideMenuItem('/SystemLog', intl.formatMessage({id: MessageTypeString(EnumMessageType.System)}), <SVGIcon.SystemLog />)}
							{renderSideMenuItem('/ShareLink', intl.formatMessage({id: "share_links", defaultMessage: "Share Links"}), <SVGIcon.ShareLink />)}
							{renderSideMenuItem('/Alert', intl.formatMessage({id: "alert", defaultMessage: "Alert"}), <SVGIcon.Alert />)}
							{renderSideMenuItem('/AccessRule', intl.formatMessage({id: "access_rule", defaultMessage: "Access Rule"}), <SVGIcon.AccessRule />)}
							{
								accountInfo.type !== EnumAccountType.user &&
								<>
									{renderSideMenuItem('/SpecialDay', intl.formatMessage({id: "special_day", defaultMessage: "Special Day"}), <SVGIcon.SpecialDay />)}
									{renderSideMenuItem('/Region', intl.formatMessage({id: "region_device", defaultMessage:"Region / Device"}), <SVGIcon.Region />)}
								</>
							}
							{renderSideMenuItem('/Operators', intl.formatMessage({id: "operator_privileges", defaultMessage: "Operator Privileges"}), <SVGIcon.Operator />)}
							{
								accountInfo.type === EnumAccountType.admin && process.env.REACT_APP_FAE_ACCOUNT.includes(accountInfo.aid) &&
								renderSideMenuItem('/AccountStatistic', 'Account Statistic', <SVGIcon.Star />)
							}
						</ul>
						
						{
							accountInfo.type !== EnumAccountType.user &&
							<Tooltip placement='right' title={<FormattedMessage id="lockdown" defaultMessage="Lockdown" />} trigger={isOpen ? [] : ['hover']}>
								<button className='app-sidebar-lockdown' onClick={() => setShowLockdown(true)}>
									<div>
										<SVGIcon.Lockdown />
									</div>
									<span title={intl.formatMessage({id: 'lockdown'})}><FormattedMessage id="lockdown" defaultMessage="Lockdown" /></span>
								</button>
							</Tooltip>
						}
						
						<div className='app-sidebar-photo' onClick={e => setShowAccount(!showAccount)}>
							<div className='gv-user-avatar'>{profileAvatar}</div>
							<span>{accountInfo.name}</span>
							{
								accountInfo.suspend_time &&
								<Tooltip placement='right' title={<FormattedMessage id='account_suspended_format' values={{0: moment.utc(accountInfo.suspend_time).local().format('YYYY/MM/DD')}} />}>
									<SVGIcon.FirmwareDirty className='suspend' />
								</Tooltip>
							}
						</div>
						<div className={'gv-account-panel' + (showAccount ? ' show' : '')}>
							<div className='gv-user-avatar'>{profileAvatar}</div>
							<span className='name'>{accountInfo.name}</span>
							<span className='email'>{accountInfo.email}</span>
							<a className='setting' href={`${Constants.gvCloudUrl}settings`} target="_blank" rel="noopener noreferrer" onClick={() => setShowAccount(false)}>
								<FormattedMessage id="account_setting" defaultMessage="Account Setting" />&nbsp;<SVGIcon.Edit />
							</a>
							<a className='logout' href={`${Constants.gvCloudUrl}logout?redirect=/access&pathname=${window.location.pathname}`} onClick={handleLogoutClick}><SVGIcon.Logout />&nbsp;<FormattedMessage id="logout" defaultMessage="LOGOUT" /></a>
						</div>
						<div className={'siderbar-dock-button' + (isOpen? ' open' : '')} onClick={handleSidebarDock}>
							{isOpen? <SVGIcon.DockClose /> : <SVGIcon.DockOpen /> }
						</div>
					</aside>
					<div className="app-content">
						<Routes>
							<Route path="/Monitor" element={<Suspense fallback={<LoadingMask show={true} />}><Monitor/></Suspense>}/>
							<Route path="/UserList" element={<Suspense fallback={<LoadingMask show={true} />}><UserList/></Suspense>}/>
							<Route path="/UserList/Setting" element={<Suspense fallback={<LoadingMask show={true} />}><UserSetting/></Suspense>}/>
							<Route path="/UserList/Setting/:id" element={<Suspense fallback={<LoadingMask show={true} />}><UserSetting /></Suspense>} />
							<Route path="/AccessLog" element={<Suspense fallback={<LoadingMask show={true} />}><AccessLog/></Suspense>}/>
							<Route path="/SystemLog" element={<Suspense fallback={<LoadingMask show={true} />}><SystemLog/></Suspense>}/>
							<Route path="/ShareLink" element={<Suspense fallback={<LoadingMask show={true} />}><ShareLink/></Suspense>}/>
							{/* {
								process.env.REACT_APP_ENABLE_VEHICLE &&
								<Route path="/LPRLog" element={<Suspense fallback={<LoadingMask show={true} />}><LPRLog/></Suspense>}/>
							} */}
							<Route path="/Operators" element={<Suspense fallback={<LoadingMask show={true} />}><Operators/></Suspense>}/>
							<Route path="/AccessRule" element={<Suspense fallback={<LoadingMask show={true} />}><AccessRule/></Suspense>}/>
							<Route path="/Alert" element={<Suspense fallback={<LoadingMask show={true} />}><Alert/></Suspense>}/>
							{
								accountInfo.type !== EnumAccountType.user &&
								<>
									<Route path="/Region" element={<Suspense fallback={<LoadingMask show={true} />}><Region/></Suspense>}/>
									<Route path="/SpecialDay" element={<Suspense fallback={<LoadingMask show={true} />}><SpecialDay/></Suspense>}/>
								</>
							}
							{
								accountInfo.type === EnumAccountType.admin && process.env.REACT_APP_FAE_ACCOUNT.includes(accountInfo.aid) &&
								<Route path="/AccountStatistic" element={<Suspense fallback={<LoadingMask show={true} />}><AccountStatistic/></Suspense>}/>
							}
							<Route path='*' element={<Navigate to="/Monitor" replace />} />
						</Routes>
					</div>
				</div>

				{
					!!accountInfo.id &&
					<Suspense fallback={<LoadingMask show={true} />}><Notification uid={CryptoJS.MD5(accountInfo.id.toString(16)).toString()} /></Suspense>
				}

				<Suspense fallback={<LoadingMask show={true} />}><DoorBellSummary /></Suspense>
				<Suspense fallback={<LoadingMask show={true} />}><ImportUserState /></Suspense>

				<Suspense fallback={<LoadingMask show={true} />}>
					<Scenario show={showLockdown} onClose={() => setShowLockdown(false)} />
				</Suspense>
			</div>
			:
			<LoadingMask show={true} />
		}

			<ShowAlertMessage {...alertMsg} show={!!alertMsg.msg} onClose={alertMsg.onClose || handelCloseAlertMsg} />
		</BrowserRouter>
	);
}

export default function App() {
	const isShareLink = window.location.pathname.indexOf('/share_link') === 0;
	return (
		<AppContextProvider>
		{
			isShareLink ?
			<BrowserRouter>
				<Routes>
					<Route path="/share_link/*" element={<Suspense fallback={<LoadingMask show={true} text='' />}><ClientShareLink /></Suspense>} />
				</Routes>
			</BrowserRouter>
			:
			<Application/>
		}
		</AppContextProvider>
	);
}

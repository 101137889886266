import { forwardRef } from 'react';

import { ReactComponent as LogoAccessControl } from './images/sidebar/logo_access_control.svg';
import { ReactComponent as LogoVideoMonitor } from './images/sidebar/logo_video_monitor.svg';
import { ReactComponent as LogoMap } from './images/sidebar/logo_map.svg';
import { ReactComponent as LogoIOT } from './images/sidebar/logo_iot.svg';
import { ReactComponent as LogoAccountSetting } from './images/sidebar/logo_account_setting.svg';
import { ReactComponent as LogoAuditLog } from './images/sidebar/logo_audit_log.svg';
import { ReactComponent as LogoVPN } from './images/sidebar/logo_vpn.svg';

import { ReactComponent as Home } from './images/sidebar/home.svg';
import { ReactComponent as Monitoring } from './images/sidebar/monitoring.svg';
import { ReactComponent as UserList } from './images/sidebar/user_list.svg';
import { ReactComponent as AccessRule } from './images/sidebar/access_rule.svg';
import { ReactComponent as SpecialDay } from './images/sidebar/special_day.svg';
import { ReactComponent as Alert } from './images/sidebar/alert.svg';
import { ReactComponent as AccessLog } from './images/sidebar/access_log.svg';
import { ReactComponent as LPRLog } from './images/sidebar/lpr_log.svg';
import { ReactComponent as SystemLog } from './images/sidebar/system_log.svg';
import { ReactComponent as Operator } from './images/sidebar/operator.svg';
import { ReactComponent as ShareLink } from './images/sidebar/share_link.svg';
import { ReactComponent as DockOpen } from './images/sidebar/sidebar_open.svg';
import { ReactComponent as DockClose } from './images/sidebar/siderbar_close.svg';
import { ReactComponent as Logout } from './images/sidebar/logout.svg';
import { ReactComponent as Lockdown } from './images/lockdown.svg';
import { ReactComponent as LockdownFail } from './images/lockdown_fail.svg';

import { ReactComponent as Setting } from './images/setting.svg';
import { ReactComponent as Search } from './images/search.svg';
import { ReactComponent as SearchClear } from './images/search_clear.svg';
import { ReactComponent as EditRaw } from './images/edit.svg';
import { ReactComponent as Copy } from './images/copy.svg';
import { ReactComponent as Paste } from './images/paste.svg';
import { ReactComponent as Trash } from './images/trash.svg';
import { ReactComponent as Sort } from './images/sort.svg';
import { ReactComponent as Export } from './images/export.svg';
import { ReactComponent as Import } from './images/import.svg';
import { ReactComponent as ImportPhoto } from './images/import_photo.svg';
import { ReactComponent as AdvanceMode } from './images/advance_mode.svg';
import { ReactComponent as EmptySnapshot } from './images/snapshot_empty.svg';
import { ReactComponent as EmptyUserPhoto } from './images/user_noimg.svg';
import { ReactComponent as NoDataRaw } from './images/no_data.svg';
import { ReactComponent as DeleteCircleRaw } from './images/delete_red.svg';
import { ReactComponent as PasswordShow } from './images/pwd_show.svg';
import { ReactComponent as PasswordHide } from './images/pwd_hide.svg';
import { ReactComponent as Add } from './images/add.svg';
import { ReactComponent as Delete } from './images/delete.svg';
import { ReactComponent as Rename } from './images/rename.svg';
import { ReactComponent as StarRaw } from './images/star.svg';
import { ReactComponent as StarOutlineRaw } from './images/star_outline.svg';
import { ReactComponent as ArrowDropDownRaw } from './images/arrow_drop_down.svg';
import { ReactComponent as DatePicker } from './images/date_picker.svg';

import { ReactComponent as Region } from './images/region.svg';
import { ReactComponent as RegionAdd } from './images/region_add.svg';
import { ReactComponent as DeviceRaw } from './images/device.svg';
import { ReactComponent as Gate } from './images/gate.svg';
import { ReactComponent as GateGreen } from './images/gate_green.svg';
import { ReactComponent as GateGreenOpen } from './images/gate_green_open.svg';
import { ReactComponent as GateRed } from './images/gate_red.svg';
import { ReactComponent as GateRedOpen } from './images/gate_red_open.svg';
import { ReactComponent as GateDisconnected } from './images/gate_disconnected.svg';
import { ReactComponent as GateForceLock } from './images/gate_force_lock.svg';
import { ReactComponent as GateForceUnlock } from './images/gate_force_unlock.svg';
import { ReactComponent as GateNoLicense } from './images/gate_no_license.svg';
import { ReactComponent as GateRelease } from './images/gate_release.svg';
import { ReactComponent as GateAdd } from './images/gate_add.svg';
import { ReactComponent as Lock } from './images/lock.svg';
import { ReactComponent as Unlock } from './images/unlock.svg';
import { ReactComponent as Sync } from './images/sync.svg';
import { ReactComponent as UpdateAll } from './images/update_all_device.svg';
import { ReactComponent as FirmwareDirty } from './images/firmware_dirty.svg';
import { ReactComponent as DaylightSaving } from './images/daylight_saving.svg';

import { ReactComponent as Lane } from './images/lane.svg';
import { ReactComponent as LaneOpen } from './images/lane_open.svg';

import { ReactComponent as AuthAuthentication } from './images/auth_authentication.svg';
import { ReactComponent as AuthRelease } from './images/auth_release.svg';
import { ReactComponent as AuthReleaseCard } from './images/auth_release_card.svg';
import { ReactComponent as AuthScheduleCopy } from './images/auth_schedule_copy.svg';

import { ReactComponent as DoorAlarm } from './images/door_alarm/alarm.svg';
import { ReactComponent as DoorDepredation } from './images/door_alarm/depredation.svg';
import { ReactComponent as DoorDuress } from './images/door_alarm/duress.svg';
import { ReactComponent as DoorFire } from './images/door_alarm/fire.svg';
import { ReactComponent as DoorForceOpen } from './images/door_alarm/forceopen.svg';
import { ReactComponent as DoorHeldOpen } from './images/door_alarm/heldopen.svg';
import { ReactComponent as DoorTailgating } from './images/door_alarm/tailgating.svg';
import { ReactComponent as DoorCamDisconnected } from './images/door_alarm/cam_disconnected.svg';

import { ReactComponent as IntercomActive } from './images/intercom_active.svg';
import { ReactComponent as IntercomAnswering } from './images/intercom_answering.svg';

import { ReactComponent as FillterGate } from './images/fillter_gate.svg';
import { ReactComponent as FillterMessage } from './images/fillter_message.svg';
import { ReactComponent as LiveVideo } from './images/live_video.svg';
import { ReactComponent as Playback } from './images/playback.svg';
import { ReactComponent as Snapshot } from './images/snapshot.svg';
import { ReactComponent as VideoPause } from './images/video_pause.svg';
import { ReactComponent as VideoPlay } from './images/video_play.svg';
import { ReactComponent as WSplayerDownload } from './images/wsplayer_download.svg';
import { ReactComponent as WSplayer } from './images/wsplayer.svg';
import { ReactComponent as JSplayer } from './images/jsplayer.svg';

import { ReactComponent as AccessKey } from './images/access_key.svg';
import { ReactComponent as AccessKeyAdd } from './images/access_key_add.svg';
import { ReactComponent as FactorCard } from './images/factor_card.svg';
import { ReactComponent as FactorVehicle } from './images/factor_vehicle.svg';
import { ReactComponent as FactorFace } from './images/factor_face.svg';
import { ReactComponent as FactorFinger } from './images/factor_finger.svg';
import { ReactComponent as PassCode } from './images/passcode.svg';
import { ReactComponent as PinCode } from './images/pin_code.svg';
import { ReactComponent as Password } from './images/password.svg';

import { ReactComponent as TimezoneGranted } from './images/timezone_granted.svg';
import { ReactComponent as TimezoneDenied } from './images/timezone_denied.svg';
import { ReactComponent as TimezoneApply } from './images/timezone_apply.svg';
import { ReactComponent as Preview } from './images/preview.svg';

import { ReactComponent as QueryPhotoMode } from './images/querylog_photo.svg';
import { ReactComponent as QueryListMode } from './images/querylog_list.svg';

import { ReactComponent as UserAdd } from './images/user_adduser.svg';
import { ReactComponent as UserDel } from './images/user_deluser.svg';
import { ReactComponent as OrganizationLvl1 } from './images/organization_leve1.svg';
import { ReactComponent as OrganizationLvl2 } from './images/organization_leve2.svg';
import { ReactComponent as OrganizationLvl3 } from './images/organization_leve3.svg';
import { ReactComponent as UniqueKey } from './images/unique_key.svg';
import { ReactComponent as MappingKey } from './images/mapping_key.svg';

import { ReactComponent as MobileRaw } from './images/mobile.svg';
import { ReactComponent as Bell } from './images/alert/bell.svg';
import { ReactComponent as Line } from './images/alert/line.svg';
import { ReactComponent as Mail } from './images/alert/mail.svg';
import { ReactComponent as SMS } from './images/alert/sms.svg';
import { ReactComponent as Messenger } from './images/alert/messenger.svg';
import { ReactComponent as Whatsapp } from './images/alert/whatsapp.svg';

import { ReactComponent as Speaker } from './images/speaker.svg';
import { ReactComponent as Clear } from './images/clear.svg';
import { ReactComponent as Info } from './images/ic_info.svg';
import { ReactComponent as InfoFill } from './images/ic_info_fill.svg';
import { ReactComponent as Warning } from './images/ic_warning.svg';

import { ReactComponent as SharedLink } from './images/shared_link.svg';
import { ReactComponent as MailSharedLink } from './images/mail_shared_link.svg';

const Device = forwardRef((props, ref) => {
    return <DeviceRaw {...props} ref={ref} className={`device-icon ${props.className || ''}`} />
});

const DeleteCircle = forwardRef((props, ref) => {
    return <DeleteCircleRaw {...props} ref={ref} className={`delete-circle ${props.className || ''}`} />
});

const Edit = forwardRef((props, ref) => {
    return <EditRaw {...props} ref={ref} className={`edit-icon ${props.className || ''}`} />
});

const ArrowDropDown = forwardRef((props, ref) => {
    return <ArrowDropDownRaw {...props} ref={ref} className={`icon-arrow-drop-down ${props.className || ''}`} />
});

const NoData = forwardRef((props, ref) => {
    return <NoDataRaw {...props} ref={ref} className={`icon-no-data ${props.className || ''}`} />
});

const Star = forwardRef((props, ref) => {
    return <StarRaw {...props} ref={ref} className={`icon-star ${props.className || ''}`} />
});
const StarOutline = forwardRef((props, ref) => {
    return <StarOutlineRaw {...props} ref={ref} className={`icon-star-outline ${props.className || ''}`} />
});

const Mobile = forwardRef((props, ref) => {
    return <MobileRaw {...props} ref={ref} className={`icon-mobile ${props.className || ''}`} />
});

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    LogoAccessControl, LogoVideoMonitor, LogoMap, LogoIOT, LogoAccountSetting, LogoAuditLog, LogoVPN,
    Home, Monitoring, UserList, AccessRule, SpecialDay, Alert, AccessLog, LPRLog, SystemLog, Operator, ShareLink, DockOpen, DockClose, Logout, Lockdown, LockdownFail,
    Setting, Search, SearchClear, Edit, Copy, Paste, Trash, Sort, Export, Import, ImportPhoto, AdvanceMode, EmptySnapshot, EmptyUserPhoto, NoData, DeleteCircle, PasswordShow, PasswordHide, Add, Delete, Rename, Star, StarOutline, ArrowDropDown, DatePicker,
    Region, RegionAdd, Device, Gate, GateGreen, GateGreenOpen, GateRed, GateRedOpen, GateDisconnected, GateForceLock, GateForceUnlock, GateNoLicense, GateRelease, GateAdd, Lock, Unlock, Sync, UpdateAll, FirmwareDirty, DaylightSaving,
    Lane, LaneOpen,
    AuthAuthentication, AuthRelease, AuthReleaseCard, AuthScheduleCopy,
    DoorAlarm, DoorDepredation, DoorDuress, DoorFire, DoorForceOpen, DoorHeldOpen, DoorTailgating, DoorCamDisconnected, IntercomActive, IntercomAnswering,
    FillterGate, FillterMessage, LiveVideo, Playback, Snapshot, VideoPause, VideoPlay, WSplayerDownload, WSplayer, JSplayer,
    AccessKey, AccessKeyAdd, FactorCard, FactorVehicle, FactorFace, FactorFinger, PassCode, PinCode, Password,
    TimezoneGranted, TimezoneDenied, TimezoneApply, Preview,
    QueryPhotoMode, QueryListMode,
    UserAdd, UserDel, OrganizationLvl1, OrganizationLvl2, OrganizationLvl3, UniqueKey, MappingKey,
    Mobile, Bell, Line, Mail, SMS, Messenger, Whatsapp,
    Speaker, Clear, Info, InfoFill, Warning,
    SharedLink, MailSharedLink
};
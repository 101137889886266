
import React from 'react';
import { IntlProvider, FormattedMessage, useIntl } from "react-intl";
import jwt_decode from "jwt-decode";

import moment from 'moment';
import 'moment/min/locales';
import momentTZ from 'moment-timezone';
import { getTimeZones } from "@vvo/tzdb";

import { EnumServiceType, EnumLogType, EnumGateFormat, EnumThemeType, EnumActionType, EnumAccountType, EnumErrorCode, EnumErrorMessage,
    EnumWebSocketCmd, EnumGateStatus, EnumASCloudAPIType, EnumDataType, EnumLogMessage, EnumMessageType, EnumDeviceStatus } from './modules/ASUtils/ASConfig';
import ASUtils, { isWinPlatform, ASConfigComponent, WsPlayerDetector, checkStatus, utc2localtime, setLogSnapshotsSatuses } from './modules/ASUtils/ASUtils';
import VMSGraphql from './modules/VMS/VMSGraphql';
import AuditLog from './modules/ASUtils/AuditLog';

export const Constants = {
    gvCloudUrl: process.env.REACT_APP_URL_BASE,
    gvMapUrl: process.env.REACT_APP_URL_MAP,
    urls: {
        access: `${process.env.REACT_APP_URL_ASCLOUD_API}ascloud-access`,
        querylog: `${process.env.REACT_APP_URL_ASCLOUD_API}log`,
        getImage: `${process.env.REACT_APP_URL_ASCLOUD_API}image`,
        ascloudFunction: `${process.env.REACT_APP_URL_ASCLOUD_API}${process.env.REACT_APP_TYPE !== 'dev' ? 'ascloud-function' : 'ascloudFunction'}`
    },
    thirdPartyUrls: {
        verifyUser: `${process.env.REACT_APP_URL_GVCLOUD_API}asm/user`,
        refreshGVToken: `${process.env.REACT_APP_URL_GVCLOUD_API}asm/refresh/token`,
        attachmentToken: `${process.env.REACT_APP_URL_GVCLOUD_API}asm/event/attachment/token`,
        mobileList: `${process.env.REACT_APP_URL_GVCLOUD_API}asm/mobiles`,
        sendMail: `${process.env.REACT_APP_URL_GVCLOUD_API}asm/email`,
        resouce: `https://cloud-center-data${process.env.REACT_APP_TYPE !== 'release' ? '-stage' : ''}.s3.us-west-1.amazonaws.com/resource/`
    },
    storageNames: {
        theme: 'theme',
        accountInfo: 'accountInfo',
        openSiderbar: 'openSiderbar',
        timezoneShedule: 'timezoneShedule',
        photoViewConfig: 'photoViewConfig',
        logViewMode: 'logViewMode',
        historyLogViewMode: 'historyLogViewMode',
        autoPlayLive: 'autoPlayLive',
        autoPlayPlayback: 'autoPlayPlayback',
        disabledWSPlayer: 'disabledWSPlayer',
        itemsPerPage: 'itemsPerPage',
        exportType: 'exportType',
        printPageSize: 'printPageSize',
        printPageDir: 'printPageDir',
        monitorFilterMsgs: 'monitorFilterMsgs',
        monitorFilterGates: 'monitorFilterGates',
        uploadMappingKeys: 'uploadMappingKeys',
        uploadDefaultValues: 'uploadDefaultValues',
        accessLogFilterMsgs: 'accessLogFilterMsgs',
        lprLogFilterMsgs: 'lprLogFilterMsgs',
        systemLogFilterMsgs: 'systemLogFilterMsgs',
        smsPhoneCountry: 'smsPhoneCountry',
        notificationConfig: 'notificationConfig',
        excludeAnnIds:  'excludeAnnIds',
        importUserState: 'importUserState',
        doorBellWidth: 'doorBellWidth',
        shareLinkExpires: 'shareLinkExpires',
        showUserAdvanced: 'showUserAdvanced'
    },
    maxCardVehicleCount: 6,             // per user
    maxRegionCount: 128,
    maxAccessRuleCount: 128,
    maxSpecialDayCount: 10,
    maxAlertCount: 128,
    maxCardsAccessRuleCount: 32,        // per card or per vehicle
    maxAccessRulesSpecialDayCount: 4,   // per Access Rule
    maxAccessRuleTimePeriod: 16,        // per Access Rule Timezone
    maxAlertSMSPhoneCount: 128,
    maxPasscodeCount: 1,                // max passcode card count for one person
    defaultItemsPerPage: 50,
    infiniteScrollIniLoadCount: 50,
    infiniteScrollUpdateCount: 50,
    maxExportPdfCount: 1000,
    maxNotificationLogCount: 99,
    maxExportUserConfirmCount: 1000,    // if exceed the count, then confirm to export
    maxBatchEditCount: 1000,

    encryptKey: 'gvcloud'
};

export const defaultPhotoViewConfig = {
    paddingSize: 20,
    imageMaxWidth: 400,
    imageMaxHeight: 225,
    imageRatio: 16 / 9,
    photoSize: 60,
    fontSize: 14,
    plateNoWidth: 120,
    gateSeparator: '-',
    gateFormat: EnumGateFormat.DeviceGate,
    fixedTimestamp: 0
};
const AccountSettingFields = ['gateSeparator', 'gateFormat', 'fixedTimestamp'];

export const defaultNotificationConfig = {
    enable: true,
    placement: 'bottomRight',
    duration: 5,
    desktop: false,
    messages: (() => {
        var ids = [];
        EnumLogMessage.filter(msg => msg.warning && !msg.hidden && msg.notification).forEach(msg => {
            ids.push(msg.value);
        });
        return ids;
    })()
};

export const AppContext = React.createContext({});
export class AppContextProvider extends React.Component {

    _defaultAccountInfo = {
        // frome GVCloud
        gv_user_token: '',
        gv_exp: 0,
        lang: 'en',
        aid: 0,   // GV-Cloud Account ID
        id: 0,    // AS-Cloud Account ID
        master: 0,
        name: '',
        email: '',
        theme: EnumThemeType.OSDefault,
        profile_picture: '',
        time_format: 'MM-DD-YYYY HH:mm:ss',

        // frome ASCloud
        type: EnumAccountType.user,    // 0: general user, 1: admin, 2: Region Admin
        suspend_time: '',     // Account suspended time (If empty, not in suspended status)
        version: '',    // 1.0.0 (ASCloud version, 每碼最多3位)
    };

    _initData = {
        regionList: false,
		deviceList: false,
        accessRuleList: false,
        alertList: false,
        specialDayList: false,
        organization: false,
        accountList: false,
        scenarioList: false
	};

    _requestDeviceStatus = 0;   // 0: none, 1: requesting, 2: padding

    // for websocket
    _wsNotifys = [];

	constructor(props) {
		super(props);

        var config;
        var photoViewConfig = { ...defaultPhotoViewConfig };
        try {
            config = JSON.parse(window.localStorage.getItem(Constants.storageNames.photoViewConfig));
            if (config) {
                photoViewConfig = {
                    ...photoViewConfig,
                    ...config
                };
            }
        } catch {}

        var notificationConfig = {...defaultNotificationConfig};
        try {
            config = JSON.parse(window.localStorage.getItem(Constants.storageNames.notificationConfig));
            if (config) {
                notificationConfig = {
                    ...notificationConfig,
                    ...config
                };
            }
        } catch {}

		this.state = {
            serviceType: EnumServiceType.Access,
            locale: undefined,
            accountInfo: { 
                ...this._defaultAccountInfo,
                theme: parseInt(window.localStorage.getItem(Constants.storageNames.theme)) || EnumThemeType.OSDefault
            },
            wsPlayerConfig: {
                isSupport: false,
                url: 'https://cloud-center-data-stage.s3.us-west-1.amazonaws.com/resource/wsplayer/GeoWebPlayerSetup.exe',
                version: 0,
                version_text: '',
                current_version: 0,
                disabled: window.localStorage.getItem(Constants.storageNames.disabledWSPlayer) ? 1 : 0
            },
            
            debugMsg: this.debugMsg,

            verifyUser: this.verifyUser,
            ajaxLogin: this.ajaxLogin,
            setAccountLang: this.setAccountLang,
            updateToken: this.updateToken,

            ajaxASCloud: this.ajaxASCloud,
            ajaxThirdParty: this.ajaxThirdParty,

            sendWS: this.sendWS,
            addWSNotification: this.addWSNotification,
            removeWSNotification: this.removeWSNotification,
            getWsClientId: this.getWsClientId,

            disabledWSPlayer: this.disabledWSPlayer,

            photoViewConfig: photoViewConfig,
            setPhotoViewConfig: this.setPhotoViewConfig,
	
            notificationConfig: notificationConfig,
            setNotificationConfig: this.setNotificationConfig,

            regionList: [],
            getRegionList: this.getRegionList,
            isRegionAdmin: this.isRegionAdmin,

			deviceList: [],
			getDeviceList: this.getDeviceList,
			setDeviceList: this.setDeviceList,
            findDeviceGate: this.findDeviceGate,
            lockdownRegions: [],

            accessRuleList: [],
            getAccessRuleList: this.getAccessRuleList,
            setAccessRuleList: this.setAccessRuleList,

            alertList: [],
            getAlertList: this.getAlertList,

            specialDayList: [],
            getSpecialDayList: this.getSpecialDayList,

            organizationMap: [],
            organizationTitles: [],
            getOrganizationMap: this.getOrganizationMap,

            accountList: [],
            getAccountList: this.getAccountList,

            scenarioList: [],
            getScenarioList: this.getScenarioList,


            getAccessLogList: this.getAccessLogList,
            // getLPRLogList: this.getLPRLogList,
            getSystemLogList: this.getSystemLogList,

            getDateTimeStrFromUTC: this.getDateTimeStrFromUTC,
            getLiveStreamInfo: this.getLiveStreamInfo,

            useASConfig: this.useASConfig
		};

        this.asUtils = React.createRef();
        this.vmsGraphql = React.createRef();
        this.asConfigRef = React.createRef();
        this.auditLogRef = React.createRef();
	}

    componentDidMount() {
        this.getLocale();
        window.addEventListener('storage', this.handleStorageChange);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.accountInfo.lang !== prevState.accountInfo.lang) {
            this.getLocale();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('storage', this.handleStorageChange);
    }

    handleStorageChange = (event) => {
        try {
            if (event.key === Constants.storageNames.accountInfo || event.key === Constants.storageNames.photoViewConfig) {
                var temp = JSON.parse(event.newValue);
                if (typeof(temp) !== 'object') return;

                if (event.key === Constants.storageNames.accountInfo) {
                    this.setState({
                        accountInfo: {
                            ...this.state.accountInfo,
                            ...temp
                        }
                    });
                } else if (event.key === Constants.storageNames.photoViewConfig) {
                    var _self = this;
                    this.setState({photoViewConfig: temp}, () => {
                        _self._callWsNotify({cmd_id: EnumWebSocketCmd.PHOTO_VIEW_CONFIG_CAHNGED});
                    });
                }
                this.debugMsg('Storage', `${event.key}: ${event.newValue}`);
            }
        } catch {}
    };

    getLocale = async () => {
        var _self = this;
        try {
            const resp = await fetch(`${window.location.origin}/lang/${_self.state.accountInfo.lang}.json?${new Date().getTime()}`);
            const data = await resp.json();
            _self.setState({locale: data});
            return data;
        } catch (e) {
            console.log(e);
            return null;
        }
    };

    debugMsg = (...args) => {
        this.asUtils.current.debugMsg.apply(null, args);
    };

    /* -------- Account Start -------- */
    _setAccountStorage = () => {
        window.localStorage.setItem(Constants.storageNames.accountInfo, JSON.stringify(this.state.accountInfo));
        window.localStorage.setItem(Constants.storageNames.theme, this.state.accountInfo.theme);
    };

    _mobileLogin = (login_user) => {
        // simulate mobile login
        const urlParams = new URLSearchParams(window.location.search);

        var account_id = '', email = '', password = '';
        if (process.env.REACT_APP_TYPE !== 'release') {
            if (login_user === '2') {
                account_id = 100000;
                email = "odaru@geovision.com.tw";
                password = "password";
            } else if (login_user === '3') {
                account_id = 100000;
                email = "hanktung@geovision.com.tw";
                password = "[Xcga5AK";
            } else if (login_user === '4') {
                account_id = 372431;
                email = "hanktung@geovision.com.tw";
                password = "Admin123!";
            } else {
                account_id = 372431;
                email = "joinyang@geovision.com.tw";
                password = "!QAZ2wsx";
            }
        }

        if (urlParams.has('account')) {
            account_id = parseInt(urlParams.get('account'));
        }
        if (urlParams.has('email')) {
            email = urlParams.get('email');
        }
        if (urlParams.has('password')) {
            password = urlParams.get('password');
        }

        this.vmsGraphql.current.mutateLogin(account_id, email, password, (success, data) => {
            if (success) {
                this._setGvToken(data.token, data, () => {
                    this.vmsGraphql.current.mutationTempToken((success, token) => {
                        if (success) {
                            window.location.search = 'token=' + token;
                        } else {
                            window.location = `${Constants.gvCloudUrl}?redirect=/access&pathname=${window.location.pathname}`;
                        }
                    });
                });
            } else {
                window.location = `${Constants.gvCloudUrl}?redirect=/access&pathname=${window.location.pathname}`;
            }
        });
    };

    _getAccountInfo = () => {
        var _info = { ...this.state.accountInfo };
        if (!this.state.accountInfo.gv_user_token) {
            try {
                var temp = JSON.parse(window.localStorage.getItem(Constants.storageNames.accountInfo));

                if (temp && temp.gv_user_token) {
                    _info = {
                        ..._info,
                        ...temp,
                    };
                    this.setState(_info);
                    this.debugMsg('AccountInfo', 'Get Token From Local Storage');
                }
            } catch {}
        }
        return _info;
    };

    _setAccountInfo = (data, callback) => {
        callback = callback || function() {};

        var _info = {
            ...this.state.accountInfo,
            type: data.a_type,
            suspend_time: data.suspend_time,
            version: data.version
        };

        this.setState({accountInfo: _info}, () => {
            this._setAccountStorage();
            callback();
        });
    };

    _setGvToken = (user_token, data, callback) => {
        callback = callback || function() {};

        var _info = {
            ...this.state.accountInfo,
            gv_user_token: user_token,
            gv_exp: new Date(data.exp * 1000).getTime(),
            lang: data.language,
            aid: data.account_id,   // GV-Cloud Account ID
            id: data.user_id,       // AS-Cloud Account ID
            master: data.master,
            email: data.email,
            name: data.name,
            theme: data.theme,
            profile_picture: data.profile_picture,
            time_format: data.time_format
        };

        this.setState({accountInfo: _info}, () => {
            this.setAccountLang(data.language);
            this._setAccountStorage();
            callback();
        });
    };

    _checkNewToken = (old_token, new_token) => {
        if (old_token && old_token !== new_token) {
            const oldInfo = jwt_decode(old_token),
                  newInfo = jwt_decode(new_token);

            if (oldInfo.account_id !== newInfo.account_id || oldInfo.user_id !== newInfo.user_id) {
                if (process.env.REACT_APP_TYPE === 'release') {
                    // clear account info
                    window.localStorage.removeItem(Constants.storageNames.accountInfo);
                    window.location = `${Constants.gvCloudUrl}?redirect=/access&pathname=${window.location.pathname}`;
                } else {
                    prompt('Account Token Not Macth!!!\nPlease Copy All Infomation.', `old:[${oldInfo.account_id} - ${oldInfo.user_id}], new: [${newInfo.account_id} - ${newInfo.user_id}],\nold: ${old_token},\nnew: ${new_token}`);
                }
                return false;
            }
        }
        return true;
    };

    verifyUser = (callback) => {
        callback = callback || function(){};

        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.get('debug') === '1' || urlParams.get('debug') === '2' || urlParams.get('debug') === '3' || urlParams.get('debug') === '4') {
            this._mobileLogin(urlParams.get('debug'));
            return;
        }

        const redirectUrl = `${Constants.gvCloudUrl}?redirect=/access&pathname=${window.location.pathname}`;

        var headers = { 'Content-Type': 'application/json' };
        if (urlParams.has('token') && urlParams.get('token')) { 
            headers['x-one-time-token'] = urlParams.get('token');
            urlParams.delete('token');

            // clear account info
            window.localStorage.removeItem(Constants.storageNames.accountInfo);
            
            // remove search params
            window.history.pushState({}, document.title, `${window.location.pathname}?${urlParams.toString()}${window.location.hash}`);
            window.history.replaceState({}, document.title, `${window.location.pathname}?${urlParams.toString()}${window.location.hash}`);

        } else {
            var info = this._getAccountInfo();
            if (info.gv_user_token && info.gv_exp > new Date().getTime()) {
                headers['x-user-token'] = info.gv_user_token;
            } else {
                // clear account info
                window.localStorage.removeItem(Constants.storageNames.accountInfo);
                window.location = redirectUrl;
                return;
            }
        }

        fetch(Constants.thirdPartyUrls.verifyUser, {
            method: 'GET',
            mode: 'cors',
            headers: new Headers(headers)
        })
        .then(res => res.json())
        .then(data => {
            if (data.success && (Array.isArray(data.data.subscribed_service) && !!data.data.subscribed_service.find(item => item.service_id === EnumServiceType.Access))) {
                if (!this._checkNewToken(headers['x-user-token'], data.user_token)) return;
                this._setGvToken(data.user_token, data.data, callback);
                this.setState({
                    wsPlayerConfig: {
                        ...this.state.wsPlayerConfig,
                        ...data.wsplayer
                    }
                });
            } else {
                window.location = redirectUrl;
            }
        })
        .catch(e => {
            window.location = redirectUrl;
            console.log(e);
        });
    };

    ajaxLogin = (callback) => {
        callback = callback || function(){};
        
        this.asUtils.current.ajaxLogin((success, accountInfo) => {
            if (success) {
                this._setAccountInfo(accountInfo, () => {
                    callback(true, this.state.accountInfo);
                });
                try {
                    var accountSetting = JSON.parse(accountInfo.a_settings);
                    if (typeof(accountSetting) === 'object') {
                        this.setPhotoViewConfig({
                            ...this.state.photoViewConfig,
                            ...accountSetting
                        }, true);
                    }
                } catch {}
                this._receiveAnnouncement(accountInfo.ann_ids);
            } else {
                callback(success, accountInfo);
            }
        });
    };

    setAccountLang = (lang, callback) => {
        callback = callback || function() {};
        this.setState({
            accountInfo: {
                ...this.state.accountInfo,
                lang: lang
            }
        }, callback);
        moment.locale(lang === 'tw' ? 'zh-tw' : lang);
    };

    updateToken = () => {
        var now = new Date().getTime(),
            expTime = this.state.accountInfo.gv_exp;
        if (now < expTime && (expTime - now) < (30 * 60 * 1000)) {
            var oldToken = this.state.accountInfo.gv_user_token;

            this.ajaxThirdParty(Constants.thirdPartyUrls.refreshGVToken, 'GET', {}, data => {
                if (data.success && data.refresh_token !== oldToken) {
                    if (data.refresh_token !== oldToken) {
                        if (!this._checkNewToken(oldToken, data.refresh_token)) return;
                        
                        this.setState({
                            accountInfo: {
                                ...this.state.accountInfo,
                                gv_user_token: data.refresh_token,
                                gv_exp: new Date(data.exp * 1000).getTime()
                            }
                        }, () => {
                            this._setAccountStorage();
                            this.ajaxLogin();
                        });
                    }
                    this.debugMsg('Util', 'Update token, ' + (data.refresh_token === oldToken ? 'Same token' : 'Get new one'));
                }
            });
        }
    };
    /* -------- Account End -------- */

    /* -------- WebSocket Start -------- */
    sendWS = (params, noUpdateToken) => {
        this.asUtils.current.sendWS(params);
        
        if (!noUpdateToken) {
            this.updateToken();
        }
    };

    addWSNotification = callback => {
        if (this._wsNotifys.indexOf(callback) === -1) {
            this._wsNotifys.push(callback);
        }
    };

    removeWSNotification = callback => {
        var idx = this._wsNotifys.indexOf(callback);
        if (idx > -1) {
            this._wsNotifys.splice(idx, 1);
        }
    };

    _callWsNotify = (notifyData) => {
        this._wsNotifys.forEach(notify => {
            if (typeof(notify) === 'function') {
                notify(notifyData);
            }
        });
    };

    getWsClientId = () => {
        return this.asUtils.current.getWsClientId();
    };
    /* -------- WebSocket End -------- */

    ajaxASCloud = (url, body, callback, notManual) => {
        callback = callback || function(){};
    
        fetch(url, {
            method: "POST",
            mode: 'cors',
            headers: new Headers({
                'Content-Type': 'application/json',
                'x-user-token': this.state.accountInfo.gv_user_token
            }),
            body: JSON.stringify(this.auditLogRef.current.genAuditMemo(body))
            // body: JSON.stringify(body)
        })
        .then(res => res.json())
        .then(data => {
            if (!data) {
                data = {errcode: EnumErrorCode.ERR_INTERNAL_ERROR, errmsg: 'empty response.'};
            }
            if (data.errcode !== EnumErrorCode.ERR_SUCCESS) {
                console.log(data.errmsg);

                data.errmsg = EnumErrorMessage[data.errcode] ? <FormattedMessage id={EnumErrorMessage[data.errcode]} /> :
                                data.errmsg ? data.errmsg : <FormattedMessage id={'err_msg_internal_error'} />;

                if (typeof(data.errcode) === 'undefined' || !EnumErrorMessage[data.errcode]) {
                    data.errcode = 1;
                }
            }
            callback(data);
            if (data.errcode === EnumErrorCode.ERR_TOKEN_INVALID) {
                this._callWsNotify({cmd_id: EnumWebSocketCmd.INVALID_TOKEN});
            }
        })
        .catch(e => {
            callback({
                success: false,
                errcode: 1,
                errmsg: e.toString()
            });
            console.log(e);
        });

        if (!notManual) {
            this.updateToken();
        }
    };

    ajaxThirdParty = (url, method, params, callback) => {
        callback = callback || function(){};
        method = method || 'GET';

        var queryUrl = url;
        if (method === 'GET') {
            queryUrl = `${url}?${new URLSearchParams(params).toString()}`;
        }

        var request = {
            method: method,
            mode: 'cors',
            headers: new Headers({
                'Content-Type': 'application/json',
                'x-user-token': this.state.accountInfo.gv_user_token
            })
        };

        if (method !== 'GET') {
            request.body = JSON.stringify(params);
        }

        fetch(queryUrl, request)
        .then(res => res.json())
        .then(data => {
            callback(data);
        })
        .catch(e => {
            callback({
                success: false,
                errcode: 1,
                errmsg: e.toString()
            });
            console.log(e);
        })
    };

    _receiveNotification = data => {
		var device_mac, device_id, device_status, gate_id, gate_status, idx, devices = [].concat(this.state.deviceList);

		const getDevice = (_device_id) => {
            this.ajaxASCloud(Constants.urls.access, {api_cmd: EnumASCloudAPIType.DEVICE, action_type: EnumActionType.Query, device_id: _device_id}, (resp) => {
                if (!resp.errcode && Array.isArray(resp.data) && resp.data.length > 0) {
                    var device = {...resp.data[0]};

                    var _idx = devices.findIndex(item => item.device_id === device.device_id);
                    if (_idx > -1) {
                        devices[_idx] = device;
                    } else {
                        devices.push(device);
                    }
                    this.setDeviceList(devices);
                }
            }, true);
        };

        const updateLockdownStatus = () => {
            this.ajaxASCloud(Constants.urls.access, {api_cmd: EnumASCloudAPIType.DEVICE, action_type: EnumActionType.Query}, (resp) => {
				if (!resp.errcode) {
					resp.data.forEach(device => {
                        var _device = devices.find(item => item.device_id === device.device_id);
                        if (_device) {
                            device.gates.forEach(gate => {
                                var _gate = _device.gates.find(item => item.gate_id === gate.gate_id);
                                if (_gate) {
                                    _gate.gate_ldn = gate.gate_ldn;
                                }
                            });
                        }
					});
					this.setDeviceList(devices);
				}
			}, true);
        };

        const getGlobalData = (excludes) => {
            if (this._initData.regionList && !excludes?.regionList) this.getRegionList(true, null, true);
            if (this._initData.deviceList && !excludes?.deviceList) this.getDeviceList(true, null, true);
            if (this._initData.accessRuleList && !excludes?.accessRuleList) this.getAccessRuleList(true, null, true);
            if (this._initData.alertList && !excludes?.alertList) this.getAlertList(true, null, true);
            if (this._initData.specialDayList && !excludes?.specialDayList) this.getSpecialDayList(true, null, true);
            if (this._initData.organization && !excludes?.organization) this.getOrganizationMap(true, null, true);
            if (this._initData.accountList && !excludes?.accountList) this.getAccountList(true, null, true);
            if (this._initData.scenarioList && !excludes?.scenarioList) this.getScenarioList(true, null, true);
        };

        const handleRegionChange = () => {
            var account_id = this.state.accountInfo.id,
                account_type = this.state.accountInfo.type,
                adminRegions = [];

            const notifyAccountChanged = () => {
                var notifyData = {cmd_id: EnumWebSocketCmd.ACCOUNT_DATA_CHANGED, data1: EnumActionType.Edit};
                this._receiveNotification(notifyData);
                this._callWsNotify(notifyData);
            };
            
            this.state.regionList.forEach(region => {
                if (region.rg_admins.includes(account_id)) {
                    adminRegions.push(region.rg_id);
                }
            });
            this.ajaxLogin((success, accountInfo) => {
                if (success) {
                    if (account_type !== accountInfo.type) {
                        notifyAccountChanged();
                    } else {
                        this.getRegionList(true, resp => {
                            if (!resp.errcode) {
                                var newAdminRegions = [];
                                resp.data.forEach(region => {
                                    if (region.rg_admins.includes(account_id)) {
                                        newAdminRegions.push(region.rg_id);
                                    }
                                });

                                if (adminRegions.length !== newAdminRegions.length || adminRegions.some((val, index) => val !== newAdminRegions[index])) {
                                    notifyAccountChanged();
                                } else {
                                    getGlobalData({regionList: true, organization: true, alertList: true, specialDayList: true});
                                }
                            }
                        }, true);
                    }
                }
            });
        };

		switch (data.cmd_id) {
			case EnumWebSocketCmd.DATA_CHANGED:
                var dataTypes = parseInt(data.data1);

                if (document.cookie.includes('debug') || window.localStorage.getItem('debug')) {
                    var changedTypes = [];
                    Object.keys(EnumDataType).forEach(key => {
                        if ((dataTypes & EnumDataType[key]) === EnumDataType[key]) {
                            changedTypes.push(key);
                        }
                    });
                    this.debugMsg('DATA_CHANGED', changedTypes.join(' / '));
                }

                if ((dataTypes & EnumDataType.Region) === EnumDataType.Region) {
                    handleRegionChange();
                }

                if (this._initData.deviceList && (dataTypes & EnumDataType.Device) === EnumDataType.Device) {
                    this.getDeviceList(true, null, true);
                }

                if (this._initData.organization && ((dataTypes & EnumDataType.Organization) === EnumDataType.Organization)) {
                    this.getOrganizationMap(true, null, true);
                }

                if (this._initData.accountList && ((dataTypes & EnumDataType.AccountList) === EnumDataType.AccountList)) {
                    this.getAccountList(true, null, true);
                }

                if ((dataTypes & EnumDataType.AccessRule) === EnumDataType.AccessRule) {
                    if (this._initData.accessRuleList) this.getAccessRuleList(true, null, true);
                    if (this._initData.deviceList) this.getDeviceList(true, null, true);
                }

                if (this._initData.specialDayList && ((dataTypes & EnumDataType.SpecialDay) === EnumDataType.SpecialDay)) {
                    this.getSpecialDayList(true, null, true);
                }

                if (this._initData.scenarioList && ((dataTypes & EnumDataType.Scenario) === EnumDataType.Scenario)) {
                    this.getScenarioList(true, null, true);
                }

                // set all device dirty
                if (this._initData.deviceList && 
                    ((dataTypes & EnumDataType.User) === EnumDataType.User || (dataTypes & EnumDataType.AccessRule) === EnumDataType.AccessRule  || (dataTypes & EnumDataType.SpecialDay) === EnumDataType.SpecialDay)) {

                    devices.forEach(item => {
                        item.dirty = 1;
                    });
                    this.setDeviceList(devices);
                }

                if (this._initData.deviceList && (dataTypes & EnumDataType.GateLockdown) === EnumDataType.GateLockdown) {
                    updateLockdownStatus();
                }

				break;
			case EnumWebSocketCmd.GATE_CAM_CHANGED:
				if (!this._initData.deviceList) return;
				device_mac = data.data1;
				idx = devices.findIndex(item => item.device_mac === device_mac);
				if (idx > -1) {
					device_id = devices[idx].device_id;
					getDevice(device_id);
				}
				break;
			case EnumWebSocketCmd.DEVICE_STATUS_CHANGED:
				if (!this._initData.deviceList) return;
				device_mac = data.data1;
                device_status = parseInt(data.data2);

                idx = devices.findIndex(item => item.device_mac === device_mac);
                if (idx > -1 && Number.isInteger(device_status)) {
                    if (devices[idx].dirty && checkStatus(devices[idx].device_status, EnumDeviceStatus.SYNCING) && 
                        !checkStatus(device_status, EnumDeviceStatus.SYNCING) && !checkStatus(device_status, EnumDeviceStatus.SYNC_FAILED)) {
                        devices[idx].dirty = false;
                    }
                    devices[idx].device_status = device_status;
                    this.setDeviceList(devices);
                }
                if (this._requestDeviceStatus === 1) {
                    this._requestDeviceStatus = 2;
                }
				
				break;
			case EnumWebSocketCmd.GATE_STATUS_CHANGED:
				if (!this._initData.deviceList) return;
				if (!data.data1.toString().includes('_')) break;
				device_mac = data.data1.split('_')[0];
				gate_id = parseInt(data.data1.split('_')[1]);
				gate_status = parseInt(data.data2);
								
				var device = devices.find(item => item.device_mac === device_mac);
				if (device) {
					var gate = device.gates.find(item => item.gate_id === gate_id);
					if (gate) {
						gate.gate_status = gate_status;
						this.setDeviceList(devices);
					}
				}

                if (this._requestDeviceStatus === 1) {
                    this._requestDeviceStatus = 2;
                }
				break;

            case EnumWebSocketCmd.SERVER_MAINTAIN:
            case EnumWebSocketCmd.CLIENT_RELOAD:
                if (process.env.REACT_APP_TYPE === 'dev') {
                    this.debugMsg('Reload', (data.cmd_id === EnumWebSocketCmd.SERVER_MAINTAIN ? 'SERVER_MAINTAIN' : 'CLIENT_RELOAD') + ' to Reload page');
                    alert((data.cmd_id === EnumWebSocketCmd.SERVER_MAINTAIN ? 'SERVER_MAINTAIN' : 'CLIENT_RELOAD') + ' to Reload page');
                }
                window.location.reload();
                break;
            case EnumWebSocketCmd.CLIENT_ANNOUNCEMENT:
                this._receiveAnnouncement(data.data1);
                break;

            case EnumWebSocketCmd.RECONNECT_WEBSOCKET:
                getGlobalData();
				break;

            case EnumWebSocketCmd.VMS_WEBSOCKET:
                switch(data.type) {
                    case 'WSPLAYER_UPDATE':
                        if (data.action === 'NEW_VERSION_AVAILABLE') {
                            this.setState({
                                wsPlayerConfig: {
                                    ...this.state.wsPlayerConfig,
                                    ...data.payload
                                }
                            });
                        }
                        break;
                    case 'ACCOUNT_USER_CHANGE':
                        if (data.id !== this.state.accountInfo.id) return;
                        if (data.action === 'UPDATE' && data.name) {
                            this.setState({
                                accountInfo: {
                                    ...this.state.accountInfo,
                                    name: data.name
                                }
                            }, this._setAccountStorage);
                        }
                        break;
                    case 'USER_LANGUAGE_CHANGE':
                        this.setAccountLang(data.value, this._setAccountStorage);
                        break;
                    case 'USER_DATA_CHANGE':
                        var params = {};
                        if (data.action === 'avatar') {
                            params['profile_picture'] = data.value;
                        } else if (data.action === 'theme') {
                            params['theme'] = data.value;
                        } else if (data.action === 'time_format') {
                            params['time_format'] = data.value;
                        }
                        this.setState({
                            accountInfo: {
                                ...this.state.accountInfo,
                                ...params
                            }
                        }, this._setAccountStorage);
                        break;
                    default:
                        break;
                }
                break;
			default:
				break;
		}
	};

    _receiveAnnouncement = async (strAnnIds) => {
        if (!strAnnIds) return;
        var annIds = [];
        try {
            annIds = JSON.parse(strAnnIds);
        } catch(e) { this.debugMsg('receiveAnnouncement', e.toString()); }

        if (!Array.isArray(annIds) || annIds.length === 0) return;

        var _self = this, i;
        for (i = 0; i < annIds.length; i++) {
            if (!_self.state.locale[`ann.${annIds[i]}`]) {
                var _locale = await _self.getLocale();
                if (!_locale || !_locale[`ann.${annIds[i]}`]) continue;
            }

            _self.asConfigRef.current.showAnnouncement(annIds[i]);
        }
    };

    disabledWSPlayer = (disabled) => {
        disabled = disabled ? 1 : 0;
        this.setState({
            wsPlayerConfig: {
                ...this.state.wsPlayerConfig,
                disabled
            }
        });
        window.localStorage.setItem(Constants.storageNames.disabledWSPlayer, disabled ? '1': '');
    };

    setPhotoViewConfig = (config, withoutAccountSetting) => {
        var photoViewConfig = {...this.state.photoViewConfig};
        Object.keys(defaultPhotoViewConfig).forEach(key => {
            if (typeof(config[key]) !== 'undefined') {
                photoViewConfig[key] = config[key];
            }
        });

        var _self = this;
        this.setState({photoViewConfig}, () => {
            _self._callWsNotify({cmd_id: EnumWebSocketCmd.PHOTO_VIEW_CONFIG_CAHNGED});
        });
        window.localStorage.setItem(Constants.storageNames.photoViewConfig, JSON.stringify(photoViewConfig));

        if (!withoutAccountSetting) {
            var params = {
                api_cmd: EnumASCloudAPIType.ACCOUNT_SETTING,
                action_type: EnumActionType.Edit,
                id: this.state.accountInfo.id,
                settings: {}
            };
            AccountSettingFields.forEach(field => {
                params.settings[field] = photoViewConfig[field];
            });
            params.settings = JSON.stringify(params.settings);
            this.state.ajaxASCloud(Constants.urls.access, params);
        }
    };

    setNotificationConfig = (config) => {
        this.setState({notificationConfig: config}, () => {
            window.localStorage.setItem(Constants.storageNames.notificationConfig, JSON.stringify(config));
        });
    };

    getRegionList = (force, callback, notManual) => {
        callback = callback || function() {};
		if (force || !this._initData.regionList) {
            this.ajaxASCloud(Constants.urls.access, {api_cmd: EnumASCloudAPIType.REGION, action_type:EnumActionType.Query}, (resp) => {
				if (!resp.errcode) {
                    this.setState({
                        regionList: resp.data
                    }, () => {
                        this._initData.regionList = true;
                        callback({
                            errcode: EnumErrorCode.ERR_SUCCESS,
                            data: resp.data,
                            errmsg: ''
                        });
                    });
				} else {
					callback(resp);
				}
			}, notManual);
		} else {
			callback({
				errcode: EnumErrorCode.ERR_SUCCESS,
				data: this.state.regionList,
				errmsg: ''
			});
			return this.state.regionList;
		}
    };

    isRegionAdmin = rg_id => {
        if (this.state.accountInfo.type === EnumAccountType.admin) {
            return true;
        } else {
            if (typeof(rg_id) === 'undefined') {
                return this.state.accountInfo.type === EnumAccountType.regionAdmin
            } else {
                return this.state.regionList.some(item => item.rg_id === rg_id && item.rg_admins.includes(this.state.accountInfo.id));
            }
        }
    };

	getDeviceList = (force, callback, notManual) => {
		callback = callback || function() {};
		if (force || !this._initData.deviceList) {
            this._requestDeviceStatus = 1;
			this.ajaxASCloud(Constants.urls.access, {api_cmd: EnumASCloudAPIType.DEVICE, action_type: EnumActionType.Query}, (resp) => {
				if (!resp.errcode && Array.isArray(resp.data)) {
                    if (this._requestDeviceStatus === 2) {
                        this.getDeviceList(true, callback, notManual);
                    } else {
                        this.setDeviceList([...resp.data], () => {
                            this._requestDeviceStatus = 0;
                            callback({
                                errcode: EnumErrorCode.ERR_SUCCESS,
                                data: resp.data,
                                errmsg: ''
                            });
                        });
                    }
				} else {
                    this._requestDeviceStatus = 0;
					callback(resp);
				}
			}, notManual);
		} else {
			callback({
				errcode: EnumErrorCode.ERR_SUCCESS,
				data: this.state.deviceList,
				errmsg: ''
			});
			return this.state.deviceList;
		}
	};

	setDeviceList = (devices, callback) => {
        callback = callback || function() {};
        var lockdownRegions = [];
        devices.forEach(device => {
            var lockdownGate = device.gates.find(gate => gate.enable && (gate.gate_ldn || ((gate.gate_status & EnumGateStatus.LOCKDOWN) === EnumGateStatus.LOCKDOWN)));
            if (lockdownGate && !lockdownRegions.includes(device.rg_id)) {
                lockdownRegions.push(device.rg_id);
            }

            if (typeof(device.settings) === 'string') {
                try {
                    device.settings = JSON.parse(device.settings);
                } catch {
                    device.settings = {};
                }
            }
            if (typeof(device.support_config) === 'string') {
                try {
                    device.support_config = JSON.parse(device.support_config);
                } catch {
                    device.support_config = {};
                }
            }

            device.gates.forEach(gate => {
                if (typeof(gate.settings) === 'string') {
                    try {
                        gate.settings = JSON.parse(gate.settings);
                    } catch {
                        gate.settings = {};
                    }
                }
                if (typeof(gate.reader_settings) === 'string') {
                    try {
                        gate.reader_settings = JSON.parse(gate.reader_settings);
                    } catch {
                        gate.reader_settings = {};
                    }
                }
            });
        });

        // check device / gate name changed or deleted
        var oldDeviceList = [...this.state.deviceList],
            editedGates = [], deletedGates = [];
        oldDeviceList.forEach(device => {
            var newDevice = devices.find(item => item.device_id === device.device_id);
            if (newDevice) {
                if (newDevice.device_name !== device.device_name) {
                    editedGates.push({device_id: device.device_id, gate_id: -1, device_name: newDevice.device_name});
                }
                device.gates.forEach(gate => {
                    var newGate = newDevice.gates.find(item => item.gate_id === gate.gate_id);
                    if (newGate) {
                        if (newGate.gate_name !== gate.gate_name) {
                            editedGates.push({device_id: device.device_id, gate_id: gate.gate_id, gate_name: newGate.gate_name});
                        }
                    } else {
                        deletedGates.push({device_id: device.device_id, gate_id: gate.gate_id});
                    }
                });
            } else {
                deletedGates.push({device_id: device.device_id, gate_id: -1});
            }
        });
        
		this.setState({deviceList: devices, lockdownRegions: lockdownRegions}, () => {
            this._initData.deviceList = true;
            callback();

            if (editedGates.length > 0) {
                this._callWsNotify({cmd_id: EnumWebSocketCmd.DEVICE_GATE_NAME_CHANGED, data1: editedGates});
            }
            if (deletedGates.length > 0) {
                this._callWsNotify({cmd_id: EnumWebSocketCmd.DEVICE_GATE_DELETED, data1: deletedGates});
            }
        });
	};

    findDeviceGate = (device_id, device_mac, gate_id) => {
        var device = this.state.deviceList.find(item => item.device_id === device_id || item.device_mac === device_mac),
            gate = null;
        if (device && typeof(gate_id) !== 'undefined' && gate_id !== -1) {
            gate = device.gates.find(item => item.gate_id === gate_id);
        }

        return { device, gate };
    };

    getAccessRuleList = (force, callback, notManual) => {
        callback = callback || function(){};
        if (force || !this._initData.accessRuleList) {
            this.ajaxASCloud(Constants.urls.access, {api_cmd: EnumASCloudAPIType.ACCESSRULE, action_type: EnumActionType.Query}, (resp) => {
                if (!resp.errcode) {
					this.setAccessRuleList(resp.data, () => {
                        callback({
                            errcode: EnumErrorCode.ERR_SUCCESS,
                            data: resp.data,
                            errmsg: ''
                        });
                    });
				} else {
					callback(resp);
				}
            }, notManual);
        } else {
            callback({
				errcode: EnumErrorCode.ERR_SUCCESS,
				data: this.state.accessRuleList,
				errmsg: ''
			});
			return this.state.accessRuleList;
        }
    };

    setAccessRuleList = (list, callback) => {
        callback = callback || function() {};
        this.setState({accessRuleList: list}, () => {
            this._initData.accessRuleList = true;
            callback();
        });
    };

    getAlertList = (force, callback, notManual) => {
        callback = callback || function(){};
        if (force || !this._initData.alertList) {
            this.ajaxASCloud(Constants.urls.access, {api_cmd: EnumASCloudAPIType.NOTIFICATION, action_type: EnumActionType.Query}, (resp) => {
                if (!resp.errcode) {
                    this.setState({alertList: resp.data}, () => {
                        this._initData.alertList = true;
                        callback({
                            errcode: EnumErrorCode.ERR_SUCCESS,
                            data: resp.data,
                            errmsg: ''
                        });
                    });
				} else {
					callback(resp);
				}
            }, notManual);
        } else {
            callback({
				errcode: EnumErrorCode.ERR_SUCCESS,
				data: this.state.accessRuleList,
				errmsg: ''
			});
			return this.state.accessRuleList;
        }
    };

    getSpecialDayList = (force, callback, notManual) => {
        callback = callback || function(){};
        if (force || !this._initData.specialDayList) {
            this.ajaxASCloud(Constants.urls.access, { api_cmd: EnumASCloudAPIType.SPECIALDAY, action_type: EnumActionType.Query }, (resp) => {
                if (!resp.errcode) {
                    this.setState({specialDayList: resp.data}, () => {
                        this._initData.specialDayList = true;
                        callback({
                            errcode: EnumErrorCode.ERR_SUCCESS,
                            data: resp.data,
                            errmsg: ''
                        });
                    });
                } else {
                    callback(resp);
                }
            });
        } else {
            callback({
				errcode: EnumErrorCode.ERR_SUCCESS,
				data: this.state.specialDayList,
				errmsg: ''
			});
			return this.state.specialDayList;
        }
    };

    getOrganizationMap = (force, callback, notManual) => {
        callback = callback || function(){};

        if (force || !this._initData.organization || !this.state.organizationMap) {
            this.ajaxASCloud(Constants.urls.access, {api_cmd: EnumASCloudAPIType.OGANIZATION, action_type: EnumActionType.Query}, (resp) => {
                if (!resp.errcode) {
                    this.setState({
                        organizationMap: [...resp.data],
                        organizationTitles: [...resp.og_titles]
                    }, () => {
                        this._initData.organization = true;
                        callback(true, this.state.organizationMap, this.state.organizationTitles);
                    });
                } else {
                    callback(false, resp.errmsg);
                }
            }, notManual);
        } else {
            callback(true, this.state.organizationMap, this.state.organizationTitles);
        }
    };

    getAccountList = (force, callback, notManual) => {
        callback = callback || function(){};

        if (force || !this._initData.accountList) {
            this.ajaxASCloud(Constants.urls.access, {api_cmd: EnumASCloudAPIType.ACCOUNT, action_type: EnumActionType.Query}, (resp) => {
                if (!resp.errcode && Array.isArray(resp.data)) {
                    const accountTypeSorted = [EnumAccountType.admin, EnumAccountType.regionAdmin, EnumAccountType.user];
                    resp.data = resp.data.sort((a, b) => {
                        if (a.account_type !== b.account_type) {
                            return accountTypeSorted.indexOf(a.account_type) - accountTypeSorted.indexOf(b.account_type);
                        } else {
                            if (a.name.toUpperCase() < b.name.toUpperCase()) {
                                return -1;
                            } else if (a.name.toUpperCase() > b.name.toUpperCase()) {
                                return 1;
                            } else {
                                return 0;
                            }
                        }
                    });

                    this.setState({accountList: resp.data}, () => {
                        this._initData.accountList = true;
                        callback({
                            errcode: EnumErrorCode.ERR_SUCCESS,
                            data: resp.data,
                            errmsg: ''
                        });
                    });
                } else {
                    callback(resp);
                }
            }, notManual);
        } else {
            callback({
				errcode: EnumErrorCode.ERR_SUCCESS,
				data: this.state.accountList,
				errmsg: ''
			});
			return this.state.accountList;
        }
    };

    getScenarioList = (force, callback, notManual) => {
        callback = callback || function() {};
		if (force || !this._initData.scenarioList) {
			this.ajaxASCloud(Constants.urls.access, {api_cmd: EnumASCloudAPIType.SCENARIO, action_type: EnumActionType.Query}, (resp) => {
				if (!resp.errcode && Array.isArray(resp.data)) {
                    this.setState({scenarioList: resp.data}, () => {
                        this._initData.scenarioList = true;
                        callback({
                            errcode: EnumErrorCode.ERR_SUCCESS,
                            data: resp.data,
                            errmsg: ''
                        });
                    });
				} else {
					callback(resp);
				}
			}, notManual);
		} else {
			callback({
				errcode: EnumErrorCode.ERR_SUCCESS,
				data: this.state.scenarioList,
				errmsg: ''
			});
			return this.state.scenarioList;
		}
    };

    getAccessLogList = (param, callback) => {
        callback = callback || function() {};
        param = {
            ...param,
            log_type: EnumLogType.Access
        };
        this.ajaxASCloud(Constants.urls.querylog, param, (resp) => {
            if (!resp.errcode && Array.isArray(resp.data)) {
                
                resp.data.forEach(data=> {
                    data['log_type'] = EnumLogType.Access;

                    setLogSnapshotsSatuses(data);
                });
            }
            callback(resp);
        });
    };

    // getLPRLogList = (param, callback) => {
    //     callback = callback || function() {};
    //     param = {
    //         ...param,
    //         log_type: EnumLogType.LPR
    //     };
    //     this.ajaxASCloud(Constants.urls.querylog, param, (resp) => {
    //         if (!resp.errcode && Array.isArray(resp.data)) {
    //             resp.data.forEach(data=> {
    //                 data['log_type'] = EnumLogType.LPR;
    //             });
    //         }
    //         callback(resp);
    //     });
    // };

    getSystemLogList = (param, callback) => {
        callback = callback || function() {};
        param = {
            ...param,
            log_type: EnumLogType.System
        };
        this.ajaxASCloud(Constants.urls.querylog, param, (resp) => {
            if (!resp.errcode && Array.isArray(resp.data)) {
                resp.data.forEach(data=> {
                    data['log_type'] = EnumLogType.System;
                });
            }
            callback(resp);
        });
    };

    getDateTimeStrFromUTC = (strUTCTime) => {
        var date = new Date(utc2localtime(strUTCTime));
        return moment(date).format(this.state.accountInfo.time_format || 'MM-DD-YYYY HH:mm:ss');
    };

    getLiveStreamInfo = (host_code, camera_id) => {
        if (!this.state.accountInfo?.gv_user_token) return null;
        const { live_password, exp, user_id } = jwt_decode(this.state.accountInfo.gv_user_token);
        return {
            username: `#!c2-${host_code}:${user_id.toString(16)}:${exp.toString(16)}:${camera_id}`, // #!c2-: from GV-Cloud
            password: live_password
        };
    };

    collectLogs = (data) => {
        var deviceId = -1, deviceMac = '', accessLogs = [], lprLogs = [], sysLogs = [];
        try {
            var {device_id, device_mac, access_datas, lpr_datas, sys_datas} = JSON.parse(data);
            deviceId = typeof(deviceId) === 'undefined' ? -1 : device_id;
            deviceMac = typeof(device_mac) === 'undefined' ? '' : device_mac;
            accessLogs = Array.isArray(access_datas) ? access_datas : [];
            lprLogs = Array.isArray(lpr_datas) ? lpr_datas : [];
            sysLogs = Array.isArray(sys_datas) ? sys_datas : [];
        } catch { return; }

        var device = this.state.deviceList.find(item => item.device_id === deviceId || item.device_mac === deviceMac);
        var logs = [];
        accessLogs.concat(lprLogs).concat(sysLogs).forEach(log => {
            var msg = EnumLogMessage.find(msg => msg.value === log.msg_id);
            if (!msg) return;

            var gate;
            if (device) {
                gate = device.gates.find(gate => gate.gate_id === log.gate_id && gate.enable);
            }

            logs.push({
                ...log,
                log_type: //msg.msgType === EnumMessageType.LPR ? EnumLogType.LPR : 
                          msg.msgType === EnumMessageType.System ? EnumLogType.System :
                          EnumLogType.Access,
                msg_type: msg.msgType,
                msg_format: msg.intlFormat,
                msg_warning: msg.warning,
                rg_id: device?.rg_id,
                device_id: deviceId,
                device_mac: deviceMac,
                device_name: device?.device_name,
                gate_name: gate?.gate_name,
                gate_dir: msg.directional ? (log.gate_id % 2) : -1
            });
        });

        return logs;
    };

    handleNotify = (notifyData) => {
        if (notifyData.cmd_id === EnumWebSocketCmd.LOG_UPLOADED) {
            notifyData.data2 = this.collectLogs(notifyData.data2);
        }

        this._receiveNotification(notifyData);
        this._callWsNotify(notifyData);
    };

    handleVMSNotify = (data) => {
        if (data?.type === 'PONG') return;
        var notifyData = {
            cmd_id: EnumWebSocketCmd.VMS_WEBSOCKET,
            ...data
        };
        this._receiveNotification(notifyData);
        this._callWsNotify(notifyData);
    };

    useASConfig = (...args) => {
        var [funcName, ...parmas] = args;
        return this.asConfigRef.current[funcName](...parmas);
    };

	render() {
		return (
            <IntlProvider messages={this.state.locale} locale={this.state.accountInfo.lang}>
                <AppContext.Provider value={this.state}>
                    <VMSGraphql ref={this.vmsGraphql} onNotify={this.handleVMSNotify}>
                        <ASUtils ref={this.asUtils} serviceType={this.state.serviceType} token={this.state.accountInfo.gv_user_token} onNotify={this.handleNotify} />
                        <ASConfigComponent ref={this.asConfigRef} />
                        <AuditLog ref={this.auditLogRef}/>
                        {
                            isWinPlatform &&
                            <WsPlayerDetector onSupport={(support, version) => {if (support){this.setState({wsPlayerConfig: {...this.state.wsPlayerConfig, isSupport: support, current_version: version}})}}} />
                        }
                        {this.props.children}
                    </VMSGraphql>
                </AppContext.Provider>
            </IntlProvider>
		);
	}
}

const defaultTimezoneData = {
    gmt: 0,     // hour*60 + minutes, ex: +08:00 = 480
    dst: false, 
    dstStart: {
        month: 1,       // Start from 1
        weekNum: 1,     // Start from 1
        weekDay: 0,     // 0: Sun, 1: Mon, 2: Tue, 3: Wed, 4: Thu, 5: Fri, 6: Sat
        time: 0         // hour*60 + minutes, ex: 08:00 = 480
    },
    dstEnd: {
        month: 2,
        weekNum: 1,
        weekDay: 0,
        time: 0
    }
};

export const enumKeyCode = {
    ArrowLeft:  37,
    ArrowUp:    38,
    ArrowRight: 39,
    ArrowDown:  40
};

export function GetTimezoneData(timezoneId) {
    const list = getTimeZones();
    const weekOfMonth = input => {
        if (input.clone().add(-1, 'w').month() !== input.month()) {  // first week
            return 0;
        } if (input.clone().add(1, 'w').month() !== input.month()) {  // last week
            return 4;
        } else {
            let date = input.clone().date(input.day()).day(input.day() + 7);
            if (date.date() > 7) {
                date.day(-6);
            }

            return input.diff(date, 'weeks');
        }
    };

    var data = CopyObject(defaultTimezoneData);

    var zone = list.find(item => item.name === timezoneId);
    if (zone) {
        var janOffset = momentTZ.tz({month: 0, day: 1}, timezoneId).utcOffset();
        var junOffset = momentTZ.tz({month: 5, day: 1}, timezoneId).utcOffset();

        var bias = Math.abs(junOffset - janOffset),
            dstOffset = Math.max(junOffset, janOffset);

        data = {
            ...data,
            gmt: zone.rawOffsetInMinutes,
            dst: bias > 0
        };

        if (bias > 0) {
            let firstOfYear = moment.tz({}, timezoneId).startOf('y'),
                lastOfYear = moment.tz({}, timezoneId).endOf('y').add(1, 's').add(-1, 'd'),
                dstCrossYear = bias > 0 && firstOfYear.utcOffset() === dstOffset;

            var startDate, endDate, i;

            for (i = firstOfYear; i.isBefore(lastOfYear); i.add(1, 'd')) {
                if (i.utcOffset() === dstOffset && !dstCrossYear) {
                    startDate = i.clone().add(-1, 'd');
                    break;
                } else if (i.utcOffset() !== dstOffset && dstCrossYear) {
                    endDate = i.clone().add(-1, 'd');
                    break;
                }
            }

            if (startDate || endDate) {
                for (i = lastOfYear; i.isAfter(firstOfYear); i.add(-1, 'd')) {
                    if (i.utcOffset() === dstOffset && !dstCrossYear) {
                        endDate = i.clone();
                        break;
                    } else if (i.utcOffset() !== dstOffset && dstCrossYear) {
                        startDate = i.clone();
                        break;
                    }
                }
            }

            if (startDate && endDate) {
                var startTime = startDate.clone(), endTime = endDate.clone();
                while (startTime.utcOffset() !== dstOffset && startTime.date() === startDate.date()) {
                    startTime.add(1, 'h');
                }

                while (endTime.utcOffset() === dstOffset && endTime.date() === endDate.date()) {
                    endTime.add(1, 'h');
                }
                startDate = startTime;
                endDate = endTime;

                data = {
                    ...data,
                    dstStart: {
                        month: startDate.month() + 1,
                        weekNum: weekOfMonth(startDate) + 1,
                        weekDay: startDate.day(),
                        time: startDate.hour() * 60 + startDate.minute() - bias
                    },
                    dstEnd: {
                        month: endDate.month() + 1,
                        weekNum: weekOfMonth(endDate) + 1,
                        weekDay: endDate.day(),
                        time: endDate.hour() * 60 + endDate.minute() + bias
                    }
                };
            }

            console.log(timezoneId + ' : have DST, ' + startDate.toString() + ' - ' + endDate.toString());
        }
    }
    return data;
}

export function CopyObject(src) {
    return JSON.parse(JSON.stringify(src));
}

export function ExcludeProps(obj, ...args) {
    if (typeof(obj) === 'object') {
        var newObj = {};
        Object.keys(obj).forEach(key => {
            if (!args.includes(key)) {
                newObj[key]  = obj[key];
            }
        });
        return newObj;
    } else {
        return obj;
    }
}

export function ArrayToggle(array, value) {
    if (Array.isArray(array)) {
        var index = array.indexOf(value);
        if (index === -1) {
            array.push(value);
        } else {
            array.splice(index, 1);
        }
    }
    return array;
}

export function TrimFilterParams(params) {
    var newParams = {};
    Object.keys(params).forEach(key => {
        if (typeof(params[key]) === 'string') {
            newParams[key] = params[key].trim();
        } else {
            newParams[key] = params[key];
        }
    });
    return newParams;
}

export function IntlFormatMessage (params) {
    params = params || {};
    const intl = useIntl();
    return intl.formatMessage({
        id: params.id || '_',
        defaultMessage: params.defaultMessage || ' '
    });
}